.popup-title {
    font-weight: bold;
    font-size: 14px;
}

.popup {
    padding: 10px;
}

.legend {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    z-index: 200;
}

.legend .color-box {
    width: 10px;
    height: 10px;
}

.color-box {
    width: 10px;
    height: 10px;
}